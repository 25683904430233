// services/connectionCheck.js
import { getDocs, collection, query, limit } from 'firebase/firestore';
import { db } from './firebase'; // Use the Firestore instance from your firebase.js file

class ConnectionCheck {
  constructor() {
    this.isConnected = true; // Firestore connection
    this.isOnline = navigator.onLine; // Network connection
    this.firestoreListeners = [];
    this.networkListeners = [];
  }

  // Initialize connection checks
  init() {
    this.checkFirestoreConnection();
    this.checkNetworkConnection();
  }

  // Firestore connection listener: Check a random document from a collection
  async checkFirestoreConnection() {
    try {
      // Query a random document (in this case, limit it to 1 doc for testing connectivity)
      const randomDocQuery = query(collection(db, "families"), limit(1));
      const querySnapshot = await getDocs(randomDocQuery);

      if (!querySnapshot.empty) {
        // If the query returns at least one document, we assume Firestore is connected
        this.isConnected = true;
      } else {
        // If no documents are returned, Firestore might be disconnected
        this.isConnected = false;
      }

      this.notifyListeners();
    } catch (error) {
      // Any error in fetching the document implies connection failure
      this.isConnected = false;
      this.notifyListeners();
    }
  }

  // Network status listener
  checkNetworkConnection() {
    const updateNetworkStatus = () => {
      this.isOnline = navigator.onLine;
      this.notifyListeners();
    };

    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    this.networkListeners.push(() => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    });
  }

  // Add a listener to handle connection changes
  addListener(callback) {
    this.listeners = this.listeners || [];
    this.listeners.push(callback);
  }

  // Notify all listeners
  notifyListeners() {
    if (this.listeners) {
      this.listeners.forEach((listener) => {
        listener(this.isConnected, this.isOnline);
      });
    }
  }

  // Cleanup listeners
  cleanup() {
    this.firestoreListeners.forEach((unsubscribe) => unsubscribe());
    this.networkListeners.forEach((removeListener) => removeListener());
  }
}

// Create a singleton instance
const connectionCheck = new ConnectionCheck();

export default connectionCheck;