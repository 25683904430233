import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import GenerateProfileLink from './components/Admin/GenerateProfileLink';
import AutoLogin from './services/autoLogin';
import ProtectedRoute from './components/ProtectedRoute';
import Navigation from './components/Navigation';
import FamilyAdmin from './components/Admin/FamilyAdmin';
import TaskList from './components/Tasks/TaskList';
import AddTask from './components/Tasks/AddTask';
import TokenLogin from './components/Auth/TokenLogin';
import Profile from './components/Profile';
import UserSelection from './components/UserSelection'; // Import UserSelection
import TaskAdmin from './components/Admin/TaskAdmin'; // Import TaskAdmin
import RewardAdmin from './components/Admin/RewardAdmin'; // Import RewardAdmin
import TaskApproval from './components/Admin/TaskApproval';
import AddReward from './components/Rewards/AddReward';
import UserPage from './components/UserPage';
import RewardList from './components/Rewards/RewardList';
import RewardApproval from './components/Admin/RewardApproval';
import AdminTile from './components/Admin/AdminTile';
import { Link } from 'react-router-dom'; // Add this import
import { FaLock } from 'react-icons/fa';
import connectionCheck from './services/connectionCheck'; // Import connection check service

function HomePage() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      navigate('/user-selection');
    }
  }, [currentUser, navigate]);

  return <h1>Home Page</h1>;
}

function App() {
  const [isConnected, setIsConnected] = useState(true); // Firebase connection state
  const [isOnline, setIsOnline] = useState(true); // Network connection state

  useEffect(() => {
    // Initialize connection checks
    connectionCheck.init();

    // Add listener for connection status changes
    connectionCheck.addListener((firebaseConnected, onlineStatus) => {
      setIsConnected(firebaseConnected);
      setIsOnline(onlineStatus);
    });

    // Cleanup listeners when component unmounts
    return () => connectionCheck.cleanup();
  }, []);

  return (
    <>
      {/* Navigation */}
      <Navigation />

      {/* Connection status warnings */}
      {!isConnected && (
  <div className="connection-warning">
    <p>Lost connection to Firebase. Please refresh the page.</p>
    <button onClick={() => window.location.reload()} className="refresh-button">
      Refresh
    </button>
  </div>
)}
      {!isOnline && (
        <div className="connection-warning">
          You are offline. Please check your internet connection.
        </div>
      )}

      {/* Routes */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/tasks"
          element={
            <ProtectedRoute>
              <TaskList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-task"
          element={
            <ProtectedRoute>
              <AddTask />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-reward"
          element={
            <ProtectedRoute>
              <AddReward />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/generate-link"
          element={
            <ProtectedRoute>
              <GenerateProfileLink />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/family"
          element={
            <ProtectedRoute>
              <FamilyAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/UserPage"
          element={
            <ProtectedRoute>
              <UserPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/task"
          element={
            <ProtectedRoute>
              <TaskAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/reward"
          element={
            <ProtectedRoute>
              <RewardAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rewards"
          element={
            <ProtectedRoute>
              <RewardList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-selection"
          element={
            <ProtectedRoute>
              <UserSelection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/task-approvals"
          element={
            <ProtectedRoute>
              <TaskApproval />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/tile"
          element={
            <ProtectedRoute>
              <AdminTile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/reward-approvals"
          element={
            <ProtectedRoute>
              <RewardApproval />
            </ProtectedRoute>
          }
        />
        <Route path="/token-login" element={<TokenLogin />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="/auto-login" element={<AutoLogin />} />
      </Routes>

      {/* Admin Lock Icon */}
      <div className="fixed bottom-4 left-4">
        <Link to="/admin/tile">
          <FaLock style={{ marginLeft: '10px', fontSize: '2.5rem' }} />
        </Link>
      </div>
    </>
  );
}

export default App;