// pointsService.js

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

export const accreditPoints = async (familyId, userId, points) => {
  console.log('pointsService.js');
  const userRef = doc(db, `families/${familyId}/users`, userId);
  const userSnap = await getDoc(userRef);

if (userSnap.exists()) {
  const userData = userSnap.data();
  const currentPoints = parseInt(userData.points, 10) || 0; // Ensure current points are treated as a number

  // Explicitly convert points to a number
  const numericPoints = Number(points);

  const newPoints = currentPoints + numericPoints;

  await updateDoc(userRef, { points: newPoints });
}

  
};
